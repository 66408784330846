// Material-UI imports
import {theme} from "./styles/theme"

// Sample app imports
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import {AppBar, Button, ThemeProvider, Typography} from "@mui/material"
import {useState} from "react"

function App() {
    const [result, setResult] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [radius, setRadius] = useState('100')
    const [coordinates, setCoordinates] = useState({})
    const [geolocationStatus, setGeolocationStatus] = useState('')
    const [gtin, setGtin] = useState('')
    const [serialId, setSerialId] = useState('')

    const baseUrl = window._env_.REACT_APP_API_ENDPOINT + '/NearAccount'

    const hostname = window.location.hostname
    let apiKey

    if (hostname === 'account-proximity.prd.ejgallo.com') {
        apiKey = ''
    } else if (hostname === 'account-proximity.acc.ejgallo.com') {
        apiKey = 'uiRnCWEnEJ5LUiiJFEcxN6THa6zhfHPsM1QvDuK6'
    } else if (hostname === 'account-proximity.dev.ejgallo.com') {
        apiKey = 'XIFZvL9xzA8GE2ohgzbme5wSgboUkt4t3wGCLm0F'
    } else {
        apiKey = ''
    }

    const callApi = async () => {
        const options = {
            method: 'GET',
            headers: {
                'X-API-Key': apiKey
            }
        }

        // Home: 30.045900, -95.615840
        // Chevron: 30.044696994675636, -95.60940644582394
        // Walmart parking lot: 30.07593510543803, -95.62560547042443
        // Inside Walmart: 30.07560766439535, -95.62471663226938

        const fullUrl = baseUrl +
            `?latitude=${latitude}` +
            `&longitude=${longitude}` +
            `&distance=${radius}` +
            `&accuracy=${coordinates.accuracy || ''}` +
            `&altitude=${coordinates.altitude || ''}` +
            `&altitude_accuracy=${coordinates.altitudeAccuracy || ''}` +
            `&heading=${coordinates.heading || ''}` +
            `&speed=${coordinates.speed || ''}` +
            `&serial_id=${serialId || ''}` +
            `&gtin=${gtin || ''}`

        console.log(fullUrl)

        let response

        try {
            response = await fetch(fullUrl, options)
            if (response.ok) {
                return await response.text()
            } else {
                return "Error"
            }
        } catch (err) {
            return "Error"
        }
    }

    const getCoordinates = () => {
        if ('geolocation' in navigator) {
            const startTime = performance.now()

            setLatitude('')
            setLongitude('')
            setResult('')

            const success = (position) => {
                const endTime = performance.now()
                const elapsed = ((endTime - startTime) / 1000).toFixed(2)
                setGeolocationStatus(`Coordinates retrieved in ${elapsed} seconds.`)
                console.log(position)
                setLatitude('' + position.coords.latitude)
                setLongitude('' + position.coords.longitude)
                setCoordinates(position.coords)
            }

            const error = (err) => {
                setGeolocationStatus(`Error retrieving coordinates: ${err.message}`)
                console.error(err)
            }

            setGeolocationStatus('Retrieving coordinates.')
            navigator.geolocation.getCurrentPosition(success, error)
        } else {
            setGeolocationStatus('GeoLocation is not available.')
            console.log('geolocation is not available')
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div>
                <AppBar position="static">
                    <Typography
                        sx={{padding: '15px 0'}}
                        align="center"
                        variant="h5"
                        component="div"
                    >
                        Account Proximity Tester
                    </Typography>
                </AppBar>
            </div>
            <Box
                sx={{
                    margin: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': {m: 1},
                }}
            >
                <TextField
                    sx={{
                        width: '350px'
                    }}
                    helperText="Please enter the latitude (between -90.0 and 90.0)"
                    id="latitude-field"
                    label="Latitude"
                    value={latitude}
                    onChange={(e) => {
                        setResult('')
                        setLatitude(e.target.value)
                    }}
                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                />
                <TextField
                    sx={{
                        width: '350px'
                    }}
                    helperText="Please enter the longitude (between -180.0 and 180.0)"
                    id="longitude-field"
                    label="Longitude"
                    value={longitude}
                    onChange={(e) => {
                        setResult('')
                        setLongitude(e.target.value)
                    }}
                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                />
                <TextField
                    helperText="Please enter the search radius"
                    id="radius-field"
                    label="Radius"
                    value={radius}
                    onChange={(e) => {
                        setResult('')
                        setRadius(e.target.value)
                    }}
                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                />
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        callApi().then(r => setResult(r))
                    }}
                >Test
                </Button>
            </Box>
            <Box
                sx={{
                    margin: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': {m: 1},
                }}
            >
                <TextField
                    sx={{
                        width: '350px'
                    }}
                    helperText="Please enter the GTIN"
                    id="gtin-field"
                    label="GTIN (optional)"
                    value={gtin}
                    onChange={(e) => {
                        setResult('')
                        setGtin(e.target.value)
                    }}
                />
                <TextField
                    sx={{
                        width: '350px'
                    }}
                    helperText="Please enter the Serial ID"
                    id="serial-id-field"
                    label="Serial ID (optional)"
                    value={serialId}
                    onChange={(e) => {
                        setResult('')
                        setSerialId(e.target.value)
                    }}
                />
            </Box>
            <Box
                sx={{
                    margin: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': {m: 1},
                }}
            >
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        getCoordinates()
                    }}
                >Use GeoLocation API
                </Button>
                <Typography variant="h6" component="div">
                    {geolocationStatus}
                </Typography>
            </Box>
            <Typography
                sx={{paddingLeft: '25px'}}
                variant="h6"
                component="div"
            >
                API Result: {result}
            </Typography>
        </ThemeProvider>
    )
}

export default App
